<template>
   <div class="dowm_bg">
      <div>
        <img src="@/assets/dowm_03.png" />
        <a id="downloadwin" :href="href" target="_blank"
          ><img
            src="@/assets/dowm_07.png"
            class="animate__animated animate__pulse"
        /></a>

        <img src="@/assets/dowm_10.png" style="margin-top: 40px" />
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import mobileMixin from "@/mixin/mobile";
export default {
  name: 'download',
  mixins:[mobileMixin],
  data() {
    return {
      href: "",
    };
  },
  mounted() {
    this.queryInfo();
  },
  methods: {
    async queryInfo() {
      // axios.get('https://cms.dingdang.tw/api/landingpage').then(res=>{
      //   this.data = res.data.data
      //   console.log(this.data,'返回数据')
      // })
      let response = await fetch("https://cms.dingdang.tw/api/landingpage");
      let res = await response.json();
      console.log(res);
      this.href = res.data.attributes.downloadwin
    },
  },
}
</script>
<style scoped>

.dowm_bg {
  background: url(../../assets/doem_032.png) no-repeat left top;
  width: 100%;
  height: 826px;
}

.dowm_bg div {
  width: 300px;
  float: right;
  margin-top: 90px;
  margin-right: 14%;
  text-align: center;
}
</style>
