<template>
  <a-card
    :title="title"
    :loading="loading"
    style="width: 381px; margin-top: 20px; min-height: 400px; border-top: 4px solid #394ffa;"
  >
    <a-row :gutter="[16, 16]">
      <a-col
        :span="12"
        v-for="(tag, index) in listData"
        :key="tag.id"
        :style="{
          textAlign: index % 2 == 0 ? 'left' : 'right',
          cursor: 'pointer',
        }"
      >
        <a-tag :color="tag.attributes.color || randomColor()" @click="handleTagClick(tag)"
          >{{ tag.attributes.tag_name }}({{
            tag.attributes.articles.data.length
          }})</a-tag
        >
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
const qs = require("qs");
import { defineComponent, ref, onMounted } from "vue";
import request from "@/utils/request";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { randomColor } from "../../utils/utils";
export default defineComponent({
  props: {
    title: {
      type: String,
      default: "热门标签",
    },
  },
  setup() {
    const loading = ref(false);
    const listData = ref([]);
    const route = useRoute();
    const router = useRouter();
    const category = ref("");
    category.value = route.params.category;

    const queryList = async () => {
      loading.value = true;
      const query = qs.stringify(
        {
          sort: ["tag_name:desc"],
          populate: {
            articles: {
              fields: ["title"],
              filters: {
                category: {
                  name: {
                    $eq: category.value,
                  },
                },
              },
            },
          },
          // populate: ['articles'],
          fields: ["tag_name", "color"],
          pagination: {
            pageSize: 20,
            page: 1,
          },
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      try {
        const response = await request.get(`/tags?${query}`);
        let data = response.data.data;
        // listData.value = data
        listData.value = data
          .filter((item) => item.attributes.articles.data.length > 0)
          .sort((a, b) => {
            let _a = a.attributes.articles.data.length;
            let _b = b.attributes.articles.data.length;

            if (_a > _b) {
              // 按某种排序标准进行比较，a 小于 b
              return -1;
            }
            if (_a < _b) {
              return 1;
            }
            // a must be equal to b
            return 0;
          });

        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    // 路由变化
    onBeforeRouteUpdate((to, from) => {
      // 分类不同时候才请求数据
      if (to.params.category !== from.params.category) {
        category.value = to.params.category;
        queryList();
      }
    });

    const handleTagClick = (tag) => {
      router.push({
        path: `/web/${route.params.category}/list`,
        query: {
          tag: tag.attributes.tag_name,
        },
      });
    };
    onMounted(() => {
      queryList();
    });
    return {
      loading,
      listData,
      category,
      handleTagClick,
      randomColor
    };
  },
});
</script>

<style></style>
