<template>
  <a-card
    :title="title"
    style="width: 381px; min-height: 400px; border-top: 4px solid #394ffa;"
    :loading="loading"
  >
    <ul style="margin-left: -24px">
      <li v-for="item in listData" :key="item.id">
        <router-link :to="`/web/${category}/detail/${item.id}`">
          <a-typography-paragraph :ellipsis="true">
            <a style="font-size: 14px">
              {{ item.attributes.title }}
            </a>
          </a-typography-paragraph>
        </router-link>
      </li>
    </ul>
  </a-card>
</template>

<script>
const qs = require("qs");
import { defineComponent, ref, onMounted } from "vue";
import request from "@/utils/request";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
export default defineComponent({
  props: {
    title: {
      type: String,
      default: "热门阅读文章",
    },
  },
  setup() {
    const loading = ref(false);
    const listData = ref([]);
    const route = useRoute();
    const category = ref("");
    category.value = route.params.category;

    const queryList = async () => {
      loading.value = true;
      const query = qs.stringify(
        {
          sort: ["views:desc"],
          filters: {
            category: {
              name: {
                $eq: category.value,
              },
            },
          },
          populate: "*",
          fields: ["title", "views"],
          pagination: {
            pageSize: 10,
            page: 1,
          },
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      try {
        const response = await request.get(`/articles?${query}`);
        listData.value = response.data.data;

        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    // 路由变化
    onBeforeRouteUpdate((to, from) => {
      // 分类不同时候才请求数据
      if (to.params.category !== from.params.category) {
        category.value = to.params.category;
        queryList();
      }
    });

    onMounted(() => {
      queryList();
    });
    return {
      loading,
      listData,
      category
    };
  },
});
</script>

<style></style>
