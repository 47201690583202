<template>
  <a-breadcrumb :style="{ margin: '16px 0', textAlign: 'left' }">
    <a-breadcrumb-item>
      <router-link to="/web/home"><environment-outlined />首页</router-link>
    </a-breadcrumb-item>
    <a-breadcrumb-item
      ><router-link :to="`/web/${category}/list`">{{
        categoryName
      }}</router-link></a-breadcrumb-item
    >
    <a-breadcrumb-item v-if="isDetail">正文</a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
const qs = require("qs");
import { EnvironmentOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, onMounted } from "vue";
import request from "@/utils/request";
import { useRoute, onBeforeRouteUpdate } from "vue-router";

export default defineComponent({
  components: { EnvironmentOutlined },
  setup() {
    const breadcrumb = {
      focus: "叮当焦点",
      case: "叮当案例",
    };
    const loading = ref(false);
    const listData = ref([]);
    const route = useRoute();
    const category = ref("");
    const categoryName = ref("");
    category.value = route.params.category;
    categoryName.value = breadcrumb[route.params.category];
    

    const queryList = async () => {
      loading.value = true;
      const query = qs.stringify(
        {
          sort: ["views:desc"],
          filters: {
            category: {
              name: {
                $eq: category.value,
              },
            },
          },
          populate: "*",
          fields: ["title", "views"],
          pagination: {
            pageSize: 10,
            page: 1,
          },
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      try {
        const response = await request.get(`/articles?${query}`);
        listData.value = response.data.data;

        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    // 路由变化
    onBeforeRouteUpdate((to, from) => {
      // 分类不同时候才请求数据 
      categoryName.value = breadcrumb[to.params.category];
      if (to.params.category !== from.params.category) {
        category.value = to.params.category;
        queryList();
      }
    });

    onMounted(() => {
      queryList();
    });
    return {
      loading,
      listData,
      category,
      categoryName,
    };
  },
  computed: {
    isDetail() {
      return this.$route.fullPath.indexOf("/detail") > -1;
    },
  },
});
</script>

<style></style>
