<template>
  <a-layout id="focus-list">
    <a-layout-header
      :style="{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        height: '164px',
        background: '#fff',
        padding: '0px',
      }"
    >
      <div
        style="
          height: 100px;
          width: 1200px;
          margin: 0px auto;
          padding-top: 15px;
        "
      >
        <router-link :to="'/web/home'">
          <div class="logo" />
        </router-link>
      </div>
      <div class="dingdang-header">
        <div style="height: 100px; width: 1200px; margin: 0px auto">
          <div class="search">
            <a-input-search placeholder="输入关键字搜索" @search="onSearch">
              <template #enterButton>
                <a-button>搜索</a-button>
              </template>
            </a-input-search>
          </div>
          <a-menu
            v-model:selectedKeys="selectedKeys"
            mode="horizontal"
            theme="dark"
            :style="{ lineHeight: '64px' }"
          >
            <a-menu-item key="1"
              ><router-link to="/web/home">首 页</router-link></a-menu-item
            >
            <a-menu-item key="focus"
              ><router-link to="/web/focus/list"
                >叮当焦点</router-link
              ></a-menu-item
            >
            <a-menu-item key="case"
              ><router-link to="/web/case/list"
                >叮当案例</router-link
              ></a-menu-item
            >
          </a-menu>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content :style="{ padding: '0 50px', marginTop: '164px' }">
      <div class="dingdang-content">
        <!-- 面包屑导航 -->
        <breadcrumb />

        <a-layout>
          <!-- 列表、正文中间部分 -->
          <a-layout-content :style="{ minHeight: '280px' }">
            <div
              :style="{
                minHeight: '380px',
                width: '100%',
                maxWidth: '800px',
                borderTop: '4px solid #394ffa',
                background:'#fff'
              }"
            >
              <router-view :searchVal="searchVal"></router-view>
            </div>
          </a-layout-content>
          <!-- 侧栏 -->
          <a-layout-sider width="380" style="background: #f0f2f5">
            <!-- 热门阅读文章 -->
            <hotArticleCard></hotArticleCard>

            <!-- 热门标签 -->
            <hotTagsCard></hotTagsCard>

            <!-- 右侧广告 -->
            <adCard width="380px" group="web:list:right" />
          </a-layout-sider>
        </a-layout>
      </div>

      <sideToolVue />
    </a-layout-content>
  </a-layout>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import hotArticleCard from "./components/hot-article-card.vue";
import hotTagsCard from "./components/hot-tags-card.vue";
import breadcrumb from "./components/breadcrumb.vue";
import adCard from "./components/ad-card.vue";
import sideToolVue from "./components/side-tool.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: { hotTagsCard, hotArticleCard, adCard, breadcrumb, sideToolVue },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const searchVal = ref("");
    const currentNav = ref(["focus"]);
    // 关键字搜索
    const onSearch = (keyword) => {
      if (route.fullPath.indexOf("/list") > -1) {
        searchVal.value = keyword;
      } else {
        router.push({
          path: `/web/${route.params.category}/list`,
          query: {
            keyword: keyword,
          },
        });
      }

      console.log("搜索内容:", keyword);
    };

    onMounted(() => {
      currentNav.value = [route.params.category];
    });
    return {
      selectedKeys: currentNav,
      onSearch,
      searchVal,
    };
  },
  computed: {
    isDetail() {
      return this.$route.fullPath.indexOf("/list") > -1;
    },
  },
});
</script>
<style>
#focus-list .dingdang-content {
  width: 1200px;
  margin: 0px auto;
}
#focus-list .logo {
  width: 277px;
  height: 75px;
  /* background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0; */
  background: url(../assets/logo_04.png);
  float: left;
}
#focus-list .search {
  width: 300px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px 0px 16px 0px;
  float: right;
}
#focus-list li.ant-list-item.ant-list-item-no-flex:hover {
  background: #f2f9ff;
  /* cursor: pointer; */
}
#focus-list .ant-list-item-meta-title:hover h2 {
  color: #4b5efa;
  /* cursor: pointer; */
}
#focus-list .ant-card-head {
  /* border-top: 4px solid #394ffa; */
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
.dingdang-header {
  height: 64px;
  background: url(../assets/header.png);
}
.dingdang-header .ant-menu-overflow {
  background: none;
}
</style>
