<template>
  <div class="home">
    <div style="width: 100%" data-aos="zoom-in">
      <img src="@/assets/app/phone_03.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_05.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_07.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_08.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_09.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_10.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_11.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_12.png" style="width: 100%" alt="" />
      <img src="@/assets/app/phone_13.png" style="width: 100%" alt="" />
    </div>

    <div class="side">
      <ul>
        <li
          class="sideetel"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
          @click="handleMessage"
        >
          <message-outlined
            :style="{ fontSize: '25px', color: 'currentColor' }"
          />
          <span> 在线留言</span>
        </li>
        <li class="sideetel">
          <i
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-telegram"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
              /></svg></i
          >Telegram
          <div class="telBox son">
            <dd class="bgs1">
              <span id="contact">{{ tg }}</span>
            </dd>
          </div>
        </li>
        <li class="sidetop"><i class="bgs6"></i>返回顶部 <a-back-top /></li>
      </ul>
    </div>

    <Message ref="MessageRef"></Message>
  </div>
</template>

<script>
// @ is an alias to /src
import mobileMixin from "@/mixin/mobile";
import { MessageOutlined } from "@ant-design/icons-vue";
import Message from "../message.vue";
export default {
  name: "Home",
  components: {
    MessageOutlined,
    Message,
  },
  mixins: [mobileMixin],
  data() {
    return {
      tg: "",
      data: [],
      products: [
        {
          name: "号码对比工具",
          icon: "tb1",
          sufixIcon: "",
          desc: "快速剔除两个txt文件中的“重复数据”，千万全球数据轻松处理，更支持TXT/VCF数据格式，可分割多个指定数量导出使用。",
        },
        {
          name: "号码生成工具",
          icon: "tb2",
          sufixIcon: "",
          desc: "全球号码在线自动生成，选择国家及手机号码，输入起始号码及生成数量，一键批量自动生成手机号码。",
        },
        {
          name: "号码区分工具",
          icon: "tb3",
          sufixIcon: "",
          desc: "快速分类全球各地区文档号码，并可区分不同国家号码并导出文件",
        },
        {
          name: "精选号段",
          icon: "tb4",
          sufixIcon: "",
          desc: "预测全球号码在线比例，全球号段实时更新，实号空号预测 。",
        },
        {
          name: "号码排序",
          icon: "tb5",
          sufixIcon: "",
          desc: "将导入的千万级别数据，一键按从小到大顺序排列 。",
        },
        {
          name: "号码乱序",
          icon: "tb6",
          sufixIcon: "",
          desc: "一键打乱所有号码规则，不被轻易察觉逻辑顺序，更安全的营销。",
        },
        {
          name: "清除非手机号码",
          icon: "tb7",
          sufixIcon: "",
          desc: "剔除导入的所有非手机格式号码，节约营销成本。",
        },
        {
          name: "TXT/VCF格式导出",
          icon: "tb8",
          sufixIcon: "",
          desc: "处理后的号码可导出TXT或VCF格式，直接导入手机即可使用，方便多种营销模式。",
        },
        {
          name: "地图搜号",
          icon: "tb9",
          sufixIcon: "",
          desc: "全球地图搜索不同国家的拓展标签及关键词，提取下载公开号码。",
        },
        {
          name: "系统防护",
          icon: "tb10",
          sufixIcon: "",
          desc: "数据安全加密处理， 数据不泄漏，不储存。",
        },
      ],
    };
  },
  mounted() {
    this.queryInfo();
  },
  methods: {
    handleMessage() {
      this.$refs.MessageRef.showModal();
    },
    async queryInfo() {
      // axios.get('https://cms.dingdang.tw/api/landingpage').then(res=>{
      //   this.data = res.data.data
      //   console.log(this.data,'返回数据')
      // })
      let response = await fetch("https://cms.dingdang.tw/api/landingpage");
      let res = await response.json();
      console.log(res);
      this.tg = res.data.attributes.contact;
    },
    handleLink() {
      window.open(
        "https://www.yuque.com/docs/share/0651553e-2636-415b-bcf0-49fb6d2bcf20?#%20%E3%80%8A%E5%8F%AE%E5%BD%93%E5%85%A8%E7%90%83%E5%8F%B7%E5%8A%A9%E6%89%8B%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%E3%80%8B",
        "_blank"
      );
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  overflow-x: hidden;
  padding-bottom: 100px;
}

.dingdang-app {
  width: 100%;
}

.home .ant-back-top {
  width: 78px;
  height: 78px;
  right: 0;
  top: 80%;
  margin-top: -20px;
  // opacity: 0!important;
}

.side * {
  margin: 0px;
  padding: 0px;
  font-family: "微软雅黑";
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #686868;
}
img {
  border: 0;
}

.side {
  position: fixed;
  width: 78px;
  right: 0;
  top: 80%;
  margin-top: -200px;
  z-index: 100;
  border: 1px solid #e0e0e0;
  background: #fff;
  border-bottom: 0;
}
.side ul li {
  width: 78px;
  height: 78px;
  float: left;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  color: #333;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.side ul li:hover {
  background: #2f4bfb;
  color: #fff;
}
.side ul li:hover a {
  color: #fff;
}
.side ul li i {
  height: 25px;
  margin-bottom: 1px;
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 25px;
  margin-top: 14px;
  transition: all 0.3s;
}
.side ul li i.bgs6 {
  background-image: url(../../assets/right_pic6_on.png);
}
.side ul li .sidebox {
  position: absolute;
  width: 78px;
  height: 78px;
  top: 0;
  right: 0;
  transition: all 0.3s;
  overflow: hidden;
}
.side ul li.sidetop {
  background: #2f4bfb;
  color: #fff;
}
.side ul li.sidetop:hover {
  opacity: 0.8;
  filter: Alpha(opacity=80);
}
.side ul li.sideewm .ewBox.son {
  width: 238px;
  display: none;
  color: #363636;
  text-align: center;
  padding-top: 235px;
  position: absolute;
  left: -240px;
  top: 0;
  // background-image: url(../images/leftewm.png);
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #e0e0e0;
}
.side ul li.sideetel .telBox.son {
  width: 240px;
  height: 78px;
  display: none;
  color: #fff;
  text-align: left;
  position: absolute;
  left: -240px;
  top: 0px;
  background: #2f4bfb;
}
.side ul li.sideetel .telBox dd {
  display: block;
  height: 78px;
  overflow: hidden;
  line-height: 14px;
  font-size: 18px;
  text-align: center;
}
.side ul li.sideetel .telBox dd span {
  display: block;
  line-height: 28px;
  height: 28px;
  overflow: hidden;
  margin-top: 25px;
  font-size: 18px;
}
.side ul li.sideetel .telBox dd.bgs1 {
  background-color: #1c36d9;
}
.side ul li.sideetel .telBox dd.bgs2 {
  // background: url(../images/right_pic9.png) 28px center no-repeat;
}
.side ul li:hover .son {
  display: block !important;
  animation: fadein 1s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
<style>
.home .ant-back-top-content {
  background-color: rgba(0, 0, 0, 0);
  color: #2f4bfb;
}
.home .ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #2f4bfb;
}
.product .ant-list-item-meta-title {
  font-size: 30px;
  display: flex;
  align-items: center;
  color: #fff;
}
.product .ant-list-item-meta-avatar {
  width: 50px;
}
.product .ant-list-item-meta-title img {
  margin-right: 10px;
}
.product .ant-list-item-meta-description {
  color: #ddd;
}
.product .ant-list-item-meta-avatar {
  margin-right: 30px;
  padding-top: 10px;
}
.tb1 {
  width: 41px;
  height: 59px;
  background: url("../../assets/tb1.png");
}
.tb2 {
  width: 42px;
  height: 60px;
  background: url("../../assets/tb2.png");
}
.tb3 {
  width: 56px;
  height: 56px;
  background: url("../../assets/tb3.png");
}
.tb4 {
  width: 51px;
  height: 44px;
  background: url("../../assets/tb4.png");
}
.tb5 {
  width: 35px;
  height: 40px;
  background: url("../../assets/tb5.png");
}
.tb6 {
  width: 43px;
  height: 43px;
  background: url("../../assets/tb6.png");
}
.tb7 {
  width: 54px;
  height: 53px;
  background: url("../../assets/tb7.png");
}
.tb8 {
  width: 39px;
  height: 46px;
  background: url("../../assets/tb8.png");
}
.tb9 {
  width: 50px;
  height: 46px;
  background: url("../../assets/tb9.png");
}
.tb10 {
  width: 38px;
  height: 48px;
  background: url("../../assets/tb10.png");
}
</style>
