<template>
  <a-carousel
    class="dingdang-carousel"
    arrows
    dots-class="slick-dots slick-thumb"
  >
    <div v-for="(item, index) in listData" :key="index">
      <img
        :style="{ width: width }"
        :src="item.src"
        @click="handleClick(item.href)"
      />
    </div>
  </a-carousel>
</template>

<script>
const qs = require("qs");
import request from "@/utils/request";
export default {
  props: {
    group: String,
    width: String,
  },
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  mounted() {
    this.queryList();
  },
  methods: {
    // 获取广告
    queryList() {
      let query = qs.stringify(
        {
          filters: {
            group: {
              $eq: this.group,
            },
          },
          populate: "*",
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      request.get(`/ads?${query}`).then((res) => {
        console.log(res, "广告");
        let _ads = [];
        res.data.data.forEach((item) => {
          _ads.push({
            src:
              "https://cms.dingdang.tw" +
              item.attributes.banner.data.attributes.url,
            href: item.attributes.href,
          });
        });
        this.listData = _ads;
      });
    },
    handleClick(href) {
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped>
/* For demo */
.ant-carousel {
  width: 100%;
  margin: 30px auto;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
